import AccordionItem from './accordion-item';

const selectors = {
    accordion: '[data-accordion]',
    item: '[data-accordion-item]',
    header: '[data-accordion-header]',
    toggle: '[data-accordion-toggle]',
    toggleIcon: '[data-accordion-toggle-icon]',
    toggleLabel: '[data-accordion-toggle-label]',
    content: '[data-accordion-content]',
};

const classes = {
    booted: 'accordion--booted',
    itemOpen: 'accordion__item--open',
};

class Accordion {
    constructor(el) {
        this.$el = el;

        this.items = [];
        this.$el.querySelectorAll(selectors.item).forEach(element => {
            this.items.push(new AccordionItem(element));
        });

        this.items.forEach(item => {
            item.$el.addEventListener('accordion-item.opened', e => {
                this.closeAllItems(e.target);

                // height fix for Yotpo reviews widget on PDP
                if (document.querySelector('div[gutter=".yotpo-gutter"]')) {
                  const yotpoGutter = document.querySelector('div[gutter=".yotpo-gutter"]');
                  yotpoGutter.style.height = '4199px';
                }
            });
        });

        el.classList.add(classes.booted);
    }

    closeAllItems(except) {
        const exceptItems = Array.isArray(except) ? except : [except];

        this.items.forEach(item => {
            if (!exceptItems.includes(item.$el)) {
                item.close();
            }
        });
    }
}

document
    .querySelectorAll(selectors.accordion)
    .forEach(element => new Accordion(element));
